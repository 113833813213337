<template>
  <el-dialog
    :visible.sync="xppPaperPassword.show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="closeModal"
    :append-to-body="true"
    :modal-append-to-body="true"
    width="35%"
  >
    <div id="paperPasswordTit">{{ text.title }}</div>
    <p class="text-center" v-if="text.text">{{ text.text }}</p>
    <div class="password-container">
      <form name="paperlock" @submit="submit">
        <template v-if="state === 1">
          <div
            class="xpp-form-item"
            :class="err_input"
            data-label="密码"
            :data-warn="err_text"
          >
            <input
              type="text"
              @focus="inputFocus"
              v-model="password"
              autocomplete="off"
            />
          </div>
          <div class="display-flex" style="padding: 0 7px;">
            <el-button
              type="text"
              class="pwd-text-btn"
              @click="changeViewState(6)"
              >修改密码</el-button
            >
            <el-button
              type="text"
              class="pwd-text-btn"
              @click="changeViewState(3)"
              >忘记密码？</el-button
            >
          </div>
        </template>
        <template v-if="state === 2">
          <div
            class="xpp-form-item"
            :class="err_input"
            data-label="输入密码"
            :data-warn="err_text"
          >
            <input
              type="text"
              @focus="inputFocus"
              v-model="password"
              autocomplete="off"
            />
          </div>
        </template>
        <template v-if="state === 3">
          <div
            class="xpp-form-item password-email-code"
            :class="err_input"
            data-label="验证码"
            :data-warn="err_text"
          >
            <div class="display-flex">
              <input type="text" v-model="verifyCode" maxlength="4" />
              <el-button
                type="warning"
                :disabled="verifyCodeTime !== 60"
                @click="setTimeCountDown"
                >{{
                  verifyCodeTime === 60 ? "重新发送" : `(${verifyCodeTime}s)`
                }}</el-button
              >
            </div>
          </div>
        </template>
        <template v-if="state === 4">
          <div
            class="xpp-form-item"
            :class="err_input"
            :data-warn="err_text"
            data-label="新密码"
          >
            <input
              type="text"
              @focus="inputFocus"
              autocomplete="off"
              v-model="password"
            />
          </div>
          <div
            class="xpp-form-item"
            :class="{ 'error-input': !!err_text2.trim() }"
            data-label="再次输入"
            :data-warn="err_text2"
          >
            <input
              type="text"
              @focus="inputFocus"
              autocomplete="off"
              v-model="passwordRepeat"
            />
          </div>
        </template>
        <template v-if="state === 5">
          <div
            class="xpp-form-item"
            :class="[err_input, success_input]"
            :data-warn="err_text"
            data-label="绑定邮箱"
          >
            <input
              type="email"
              v-model="userEmail"
              placeholder="您未绑定邮箱，绑定后即可设置密码"
            />
          </div>
          <div class="xpp-form-item password-email-code" data-label="验证码">
            <div class="display-flex">
              <input type="text" v-model="verifyCode" maxlength="4" />
              <el-button
                type="warning"
                :disabled="verifyCodeTime !== 60 || !verifyFormatEmail"
                @click="setTimeCountDown"
                >{{
                  verifyCodeTime === 60 ? "发送" : `(${verifyCodeTime}s)`
                }}</el-button
              >
            </div>
          </div>
        </template>
        <template v-if="state === 6">
          <div
            class="xpp-form-item"
            :class="err_input"
            :data-warn="err_text"
            data-label="输入旧密码"
          >
            <input
              type="text"
              @focus="inputFocus"
              v-model="password"
              autocomplete="off"
            />
          </div>
          <div
            class="xpp-form-item"
            :class="{ 'error-input': !!err_text2.trim() }"
            data-label="输入新密码"
            :data-warn="err_text2"
          >
            <input
              type="text"
              @focus="inputFocus"
              v-model="passwordRepeat"
              autocomplete="off"
            />
          </div>
        </template>
        <template v-if="state === 7">
          <div
            class="xpp-form-item"
            :class="err_input"
            :data-warn="err_text"
            data-label="输入密码"
          >
            <input
              type="text"
              @focus="inputFocus"
              v-model="password"
              autocomplete="off"
            />
          </div>
        </template>
        <div class="text-center" style="padding-top: 20px;">
          <el-button class="submit-pwd-btn" type="warning" @click="submit"
            >确认</el-button
          >
        </div>
      </form>
    </div>
    <div class="password-footer display-flex" v-if="state === 1">
      <div class="display-flex"><i>这个Canvas已经被保护</i></div>
      <div class="display-flex flex-start">
        <div
          class="pwd-headimg xpp-user-photo"
          :style="founderInfo.head_img | styleUserPhoto"
        ></div>
        <div class="text-overflow">
          <b :title="founderInfo.nick_name">{{ founderInfo.nick_name }}</b
          ><br />
          <span :title="founderInfo.email">{{ founderInfo.email }}</span>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import md5 from "md5";

export default {
  name: "PaperPassword",
  props: {
    type: {
      type: Number,
      required: true
    }
  },
  computed: {
    text() {
      let text = {};
      switch (this.state) {
        case 1:
          text.title = "受保护的canvas";
          text.text = "输入密码";
          break;
        case 2:
          text.title = "设置密码";
          text.text = "设置一个密码来保护此canvas";
          break;
        case 3:
          text.title = "重置密码";
          text.text = `验证码已发送到${this.founderInfo.email}，请到邮箱内查看`;
          break;
        case 4:
          text.title = "输入新密码";
          text.text = "输入您的新密码来保护此canvas";
          break;
        case 5:
          text.title = "邮箱绑定";
          text.text = "";
          break;
        case 6:
          text.title = "修改密码";
          text.text = "输入正确的旧密码后可修改密码";
          break;
        case 7:
          text.title = "关闭密码";
          text.text = "输入密码后，移除此canvas上的密码保护";
          break;
      }
      return text;
    },
    state() {
      return this.xppPaperPassword.state;
    },
    founderInfo() {
      return this.xppPaperPassword.founderInfo || {};
    },
    closeModal() {
      return this.xppPaperPassword.state !== 1;
    },
    err_input() {
      return { "error-input": !!this.err_text.trim() };
    },
    verifyFormatEmail() {
      return this.inputPattern.test(this.userEmail);
    },
    success_input() {
      return { "success-input": this.verifyFormatEmail };
    },
    ...mapGetters(["paperGroupId", "xppPaperPassword"])
  },
  watch: {
    state(val) {
      switch (val) {
        case 2:
          if (!this.founderInfo.email) {
            this.$UpdataVuexState({
              key: "xppPaperPassword",
              data: { show: true, state: 5 }
            });
          }
          break;
        case 3:
          this.setTimeCountDown(this.verifyCodeTime);
          break;
      }
    }
  },
  data() {
    return {
      password: "",
      passwordRepeat: "",
      err_text: "",
      err_text2: "",
      userEmail: "",
      emailPwdKey: localStorage.getItem("emailPwdKey") || "",
      sendEmailTime: null,
      verifyCodeTime: localStorage.getItem("verifyCodeTime") - 0 || 60, // 发送验证码的延迟
      verifyCode: "",
      inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/
    };
  },
  mounted() {},
  methods: {
    inputFocus({ target }) {
      target.type = "password";
    },
    removeErrTxt() {
      this.err_text = "";
      this.err_text2 = "";
    },
    // 验证密码
    async checkpwd(back) {
      if (!this.password.trim()) {
        this.err_text = "输入不能为空";
        return;
      }
      let newpassword = md5(this.password);
      let { info } = await this.$axios.post("/dis/p/checkpwd", {
        id: this.paperGroupId,
        resourceType: this.type,
        newpassword
      });
      if (info) {
        if (back) {
          return back(info);
        }
        this.$emit("back", newpassword);
        return this.$UpdataVuexState({
          key: "xppPaperPassword",
          data: { show: false, state: 1 }
        });
      } else {
        this.err_text = "密码错误";
      }
    },
    // 设置密码接口
    async setpwd() {
      try {
        await this.$axios.post("/dis/p/addpwd", {
          id: this.paperGroupId,
          resourceType: this.type,
          newpassword: md5(this.password)
        });
        // location.reload();
      } catch (error) {
        console.error(error);
        this.$alert("密码设置失败，请重试");
      }
    },
    // 修改密码
    async modifyPwd() {
      if (!this.passwordRepeat.trim()) {
        this.err_text2 = "输入不能为空";
        return;
      }
      await this.$axios.post("/dis/p/addpwd", {
        id: this.paperGroupId,
        resourceType: this.type,
        oldpassword: md5(this.password),
        newpassword: md5(this.passwordRepeat)
      });
      location.reload();
    },
    // 设置密码
    verifyPwd() {
      if (!this.password.trim()) {
        this.err_text = "输入不能为空";
        return;
      }
      this.setpwd();
    },
    // 重置密码
    async repeatSetPwd() {
      if (!this.password.trim()) {
        this.err_text = "输入不能为空";
        return;
      }
      if (this.passwordRepeat !== this.password) {
        this.err_text2 = "两次输入不一致";
        return;
      }
      await this.removePwd(false);
      await this.setpwd();
    },
    // 移除密码
    removePwd(type = true) {
      this.$axios
        .post("/dis/p/closepwd", {
          id: this.paperGroupId,
          resourceType: this.type,
          oldpassword: md5(this.password)
        })
        .then(res => {
          type && res.status === 200 && location.reload();
        });
    },
    // 设置倒计时
    setTimeCountDown(time) {
      this.verifyCodeTime = typeof time === "number" ? time || 59 : 59;
      localStorage.setItem("verifyCodeTime", this.verifyCodeTime);
      this.sendEmailTime = setInterval(() => {
        this.verifyCodeTime--;
        if (this.verifyCodeTime <= 0) {
          this.verifyCodeTime = 60;
          clearInterval(this.sendEmailTime);
        }
        localStorage.setItem("verifyCodeTime", this.verifyCodeTime);
      }, 1000);
      this.sendEmailVerifyCode();
    },
    // 发送邮件验证码
    async sendEmailVerifyCode() {
      if (this.verifyCodeTime !== 60) {
        return;
      }
      let { info } = await this.$axios.post("/dis/p/emailpwd", {
        id: this.paperGroupId,
        email: this.founderInfo.email
      });
      this.emailPwdKey = info;
      localStorage.setItem("emailPwdKey", info);
    },
    // 邮箱验证码校验
    async verifyEmail() {
      if (this.verifyCode.length < 4) {
        this.err_text = "请输入正确的验证码";
        return;
      }
      if (!this.emailPwdKey.trim()) {
        this.err_text = "请先获取验证码";
        return;
      }
      let { info } = await this.$axios.post(
        `/dis/p/emailcode?code=${this.verifyCode}&key=${this.emailPwdKey}`
      );
      if (!info) {
        this.err_text = "请输入正确的验证码";
        return;
      }
      // 验证通过后重置倒计时
      localStorage.setItem("verifyCodeTime", 60);
      if (this.state === 5) {
        this.$UpdataVuexState({
          key: "xppPaperPassword",
          data: { show: true, state: 2 }
        });
      } else {
        this.$UpdataVuexState({
          key: "xppPaperPassword",
          data: { show: true, state: 4 }
        });
      }
    },
    // 切换窗口状态
    changeViewState(state) {
      this.$UpdataVuexState({
        key: "xppPaperPassword",
        data: { state }
      });
    },
    // 确认按钮绑定事件
    submit(event) {
      event.preventDefault();
      event.stopPropagation();
      this.removeErrTxt();
      switch (this.state) {
        case 1:
          this.checkpwd();
          break;
        case 2:
          this.verifyPwd();
          break;
        case 3:
          this.verifyEmail();
          break;
        case 4:
          this.repeatSetPwd();
          break;
        case 5:
          this.verifyEmail();
          break;
        case 6:
          this.checkpwd(this.modifyPwd);
          break;
        case 7:
          this.removePwd();
          break;
      }
    },
    ...mapMutations(["$UpdataVuexState"])
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .submit-pwd-btn {
    width: 200px;
  }
  .el-dialog__body {
    padding-top: 17px;
    padding-bottom: 0;
  }
}
#paperPasswordTit {
  font-size: 20px;
  color: rgba(53, 53, 53, 1);
  text-align: center;
  & + p {
    color: #848484;
    font-size: 14px;
    margin-top: 2px;
    height: 32px;
    line-height: 32px;
  }
}
.password-container {
  padding-bottom: 33px;
  padding-left: 54px;
  padding-right: 54px;
}
.xpp-form-item {
  margin-bottom: 8px;
  &::before {
    height: 32px;
    line-height: 32px;
    padding-left: 0.5em;
    color: #525252;
    margin-bottom: 1px;
  }
  &.success-input {
    &::after {
      content: "\e79c";
      font-family: element-icons !important;
      font-style: normal;
      font-weight: 400;
      color: #22b559;
      font-size: 22px;
      line-height: 22px;
      position: absolute;
      right: 13px;
      bottom: 9px;
    }
  }
}
.password-email-code {
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
  button {
    flex: none;
    width: 125px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    &.is-disabled {
      background-color: rgba(213, 213, 213, 1);
      border-color: rgba(213, 213, 213, 1);
    }
  }
}

.pwd-text-btn {
  font-size: 12px;
  color: #c3c3c3;
  &:hover {
    color: #f5a623;
  }
}
.password-footer {
  height: 87px;
  border-top: 1px solid #eeeeee;
  padding: 0 42px;
  .display-flex {
    width: 50%;
    &:first-child {
      text-align: center;
    }
  }
  i {
    color: #bdbdbd;
    font-size: 14px;
  }
  span {
    font-size: 12px;
    color: #989898;
  }
  b {
    font-size: 14px;
    font-weight: 500;
    color: #373737;
  }
  .pwd-headimg {
    flex: none;
    width: 34px;
    height: 34px;
    margin-right: 11px;
  }
}
</style>
